import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComentariosService {
  public comentarioSource = new BehaviorSubject<any>(null);
  public $comentario = this.comentarioSource.asObservable();
  public comentariosSource = new BehaviorSubject<any[]>([]);
  public $comentarios = this.comentariosSource.asObservable();
  constructor(private http: HttpClient) { }
  setComentario(comentario: any){
    this.comentarioSource.next(comentario);
  }
  setComentarios(comentarios: any[]){
    this.comentariosSource.next(comentarios);
  }
  all(idEstablecimiento: number){
    return this.http.get(`${environment.api}/calificaciones/${idEstablecimiento}`);
  }
  store(idEstablecimiento, payload: any){
    return this.http.post(`${environment.api}/calificaciones/${idEstablecimiento}`, payload);
  }
  delete(idEstablecimiento: number, idCalificacion: number){
    return this.http.delete(`${environment.api}/calificaciones/${idEstablecimiento}/${idCalificacion}`);
  }
  incumple(payload){
    return this.http.post(`${environment.api}/incumple`,payload);
  }
  privacidad(idEstablecimiento: number, idCalificacion: number){
    return this.http.get(`${environment.api}/calificaciones/${idEstablecimiento}/privacidad/${idCalificacion}`);
  }
}
