export const environment = {
  firebase: {
    projectId: 'floods-b81b8',
    appId: '1:895017913996:web:5ce677e30d4dfb8f02b5a9',
    databaseURL: 'https://floods-b81b8.firebaseio.com',
    storageBucket: 'floods-b81b8.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyDxL1Inw7zdGS0vWcPriVpPSCVdIzkCbtM',
    authDomain: 'floods-b81b8.firebaseapp.com',
    messagingSenderId: '895017913996',
    measurementId: 'G-DXQ700BK57',
  },
  production: true,
  socket:'https://socket.plataformasecturchiapas.mx',
  url: 'https://app.plataformasecturchiapas.mx',
  api: 'https://api.plataformasecturchiapas.mx/api',
  quejas:'https://plataformasecturchiapas.mx/turismo/registrar_es.php',
  encuesta:'https://plataformasecturchiapas.mx/encuestas/t/4',
  seguimiento:'https://turista.plataformasecturchiapas.mx',
  resource: 'https://plataformasecturchiapas.mx/turismo/archivos/',
  apiKeyMaps: 'AIzaSyALlnsTAtCio0TTr0nJEPgrsnxYH8YoZNE',
  apiMaps: 'https://www.google.com/maps/embed/v1',
  version:'2022.7.01'
};
