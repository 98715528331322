import { Component, Input, OnInit } from '@angular/core';

interface ImageList{
  redirect?: string;
  params?: any;
  nombre?: string;
  url: string;
  logo?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  foto_portada?: string;
}

@Component({
  selector: 'app-image-scroller',
  templateUrl: './image-scroller.component.html',
  styleUrls: ['./image-scroller.component.scss'],
})
export class ImageScrollerComponent implements OnInit {

  @Input() items: ImageList[];
  constructor() { }

  ngOnInit() {
    console.log(this.items);
    this.items = this.items.map( i =>{
      i.url = i.url ? i.url : i.logo;
      return i;
    });
  }
}
