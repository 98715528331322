import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
interface ActionSheetButton<T = any> {
  text?: string;
  role?: 'cancel' | 'destructive' | 'selected' | string;
  icon?: string;
  cssClass?: string | string[];
  handler?: () => boolean | void | Promise<boolean | void>;
  data?: T;
}
@Injectable({
  providedIn: 'root'
})
export class ActionSheetService {

  constructor(public actionSheetController: ActionSheetController) { }
  async presentActionSheet(header: string, buttons: ActionSheetButton[]) {
    const actionSheet = await this.actionSheetController.create({
      header,
      buttons
    });
    await actionSheet.present();
    const { role, data } = await actionSheet.onDidDismiss();
  }

}
