import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReservacionService {
  public reservacionesSource = new BehaviorSubject<any[]>([]);
  public $reservaciones = this.reservacionesSource.asObservable();
  constructor(private http: HttpClient, private authService: AuthService) {
    this.authService.$profile.subscribe( profile =>{
      if (profile) {
        this.getOwn(profile.id_turista).subscribe(
          (reservaciones: any[]) => {
            this.setReservaciones(reservaciones);
          },
          err => console.error(err)
        );
      }
    }, err => console.error(err));
  }
  getOwn(turistaId: number){
    return this.http.get(`${environment.api}/reservaciones/turista/${turistaId}`);
  }
  get(id: number){
    return this.http.get(`${environment.api}/reservaciones/${id}`);
  }
  store(payload: any){
    return this.http.post(`${environment.api}/reservaciones`,payload);
  }
  cancel(id: number){
    return this.http.delete(`${environment.api}/reservaciones/${id}`);
  }
  eliminar(id: number){
    return this.http.delete(`${environment.api}/reservaciones/eliminar/${id}`);
  }
  reenviar(id: number){
    return this.http.get(`${environment.api}/reservaciones/reenviar/${id}`);
  }
  proxima(turistaId: number){
    return this.http.get(`${environment.api}/reservaciones/turista/proxima/${turistaId}`);
  }
  estado(turistaId: number, estado: 'canceladas'|'proximas'|'vencidas'){
    return this.http.get(`${environment.api}/reservaciones/turista/${turistaId}/${estado}`);
  }
  setReservaciones(reservaciones: any[]){
    // console.log(reservaciones);
    this.reservacionesSource.next(reservaciones);
  }
  paga(reservacionId: number, payload: any){
    return this.http.post(`${environment.api}/pagos/${reservacionId}`, payload);
  }
}
