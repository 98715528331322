import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImageScrollerRoutingModule } from './image-scroller-routing.module';
import { ImageScrollerComponent } from './image-scroller.component';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [ImageScrollerComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ImageScrollerRoutingModule
  ],
  exports:[ ImageScrollerComponent ]
})
export class ImageScrollerModule { }
